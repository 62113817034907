<template>
	<div class="companies">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog = !dialog" title="Entreprises" content="Ajouter une Entreprise" whiteText />
				<Companies title="Entreprises" :companies="this.companies" class="maxWidth mx-auto" />
			</v-card>
		</v-container>
		
		<CompanyDialog v-model="dialog" :compArray="this.companies" :modify="false" />
	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import Companies from '@/components/modules/@company/CompaniesTable';
	import CompanyDialog from '@/components/modules/@company/CompanyDialog';

	export default {
		head: {
			title: {
				inner: "Entreprises"
			}
		},
		props: {
			'id': {
				type: String
			}
		},
		components: {
			Head,
			ButtonTrigger,
			Companies,
			CompanyDialog,
		},
		data() {
			return {
				dialog: false,
				companies: [],
				userInfo: this.$models.user,
				Head: {

					Title: 'Entreprises',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
			};
		},
		methods: {
			getCompanies() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("companies").onSnapshot((res) => {
					this.companies = [];

					res.forEach((document) => {
						this.companies.push({
							...document.data(),
							id: document.id,
						});
					});
				});
			}
		},
		watch: {
			userInfo: function () {
				this.getCompanies();
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();

				this.getCompanies();
			});
		}
	}

</script>
