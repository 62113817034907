<template>
	<v-flex>

		<v-dialog :value="value" @input="$emit('input')" transition="dialog-bottom-transition" max-width="960">
			<v-card class="glassmorphism" color="transparent">

				<ButtonTrigger title="Ajouter une Entreprise" small whiteText :noButton="$vuetify.breakpoint.smAndUp"
					icon content="" @click.native="$emit('input', false)" />

				<v-col cols="12">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form" class="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Rechercher via SIRET" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-icon dense left color="success">mdi-alert-circle-outline</v-icon>
													<span class="caption ml-1">Vous pouvez pré-remplir les champs du
														formulaire si
														l'entreprise concernée est repertoriée dans le répertoire
														SIREN.</span>
												</v-col>
												<v-col cols="12">
													<v-text-field label="SIRET" type="number" hint="SIRET du contact"
														v-model="companyEntry.siret"></v-text-field>
													<v-btn class="evidence neon float-right" @click="addCompanyBySIRET"
														outlined color="success">Rechercher
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>


				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Profil" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-text-field label="Nom" hint="Nom du contact"
														:rules="this.$fieldsRules.required('nom')"
														v-model="companyEntry.name">
													</v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Code Entreprise" hint="Code du contact"
														:rules="this.$fieldsRules.required('numéro entreprise')"
														v-model="companyEntry.nCompany"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-menu v-model="menu" :close-on-content-click="false"
														:nudge-right="40" transition="scale-transition" offset-y
														min-width="auto">
														<template v-slot:activator="{ on, attrs }">
															<v-text-field v-model="formatDate" label="Date de création"
																prepend-icon="mdi-calendar" readonly v-bind="attrs"
																v-on="on">
															</v-text-field>
														</template>
														<v-date-picker first-day-of-week="1"
															v-model="companyEntry.birthdate" @input="menu = false">
														</v-date-picker>
													</v-menu>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>


				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Contact" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-text-field label="N° de Téléphone"
														hint="N° de téléphone principal de l'entreprise"
														v-model="companyEntry.phone"></v-text-field>

												</v-col>

												<v-col cols="12">
													<v-text-field label="Adresse Mail"
														hint="Adresse mail principal de l'entreprise"
														v-model="companyEntry.email"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Site Web"
														hint="Lien du Site Web de l'Entreprise"
														v-model="companyEntry.website">
													</v-text-field>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Adresse" :noButton="false" />
												</v-col>
												<v-col cols="12">
													<v-text-field label="Adresse" hint="Adresse de l'entreprise"
														v-model="companyEntry.address.street"></v-text-field>

												</v-col>

												<v-col cols="12">
													<v-text-field label="Code Postal" hint="Code postal de l'entreprise"
														v-model="companyEntry.address.postalCode"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Région" hint="Région de l'entreprise"
														v-model="companyEntry.address.region"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Ville" hint="Ville de l'entreprise"
														v-model="companyEntry.address.city"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Pays" hint="Pays de l'entreprise"
														v-model="companyEntry.address.country"></v-text-field>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>


				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="A Propos" :noButton="false" />
												</v-col>

												<v-col cols="12">
													<v-textarea v-model="companyEntry.bio" name="input-1-1"
														label="Description" hint="Description"></v-textarea>
												</v-col>

												<v-col cols="12">
													<ImageUpload v-if="userInfo.company" :companyID="userInfo.company"
														type="company" label="Logo de l'entreprise"
														@newdata="companyEntry.avatar=$event"></ImageUpload>
													<v-img v-if="companyEntry.avatar" :src="$functions.getImgLink(companyEntry.avatar, 200)"
														contain></v-img>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<ButtonTrigger title="Informations Complémentaire"
														:noButton="false" />
												</v-col>

												<v-col cols="12">
													<Title>Préférence de canal de contact</Title>
												</v-col>

												<v-col cols="12">
													<v-col cols="4">
														<v-switch v-model="companyEntry.contact.mail" label="Mail">
														</v-switch>
													</v-col>
													<v-col cols="4">
														<v-switch v-model="companyEntry.contact.call" label="Appel">
														</v-switch>
													</v-col>
													<v-col cols="4">
														<v-switch v-model="companyEntry.contact.sms" label="SMS">
														</v-switch>
													</v-col>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Remarque"
														hint="Remarque sur les modes de communication de l'entreprise"
														v-model="companyEntry.contact.preference"></v-text-field>
												</v-col>

												<v-col cols="12">
													<Title>Informations Bancaire</Title>
												</v-col>


												<v-col cols="12">
													<v-switch v-model="companyEntry.tva.status"
														label="Assujétti à la TVA"></v-switch>

												</v-col>

												<v-col cols="12">
													<v-autocomplete :items="this.$listUtils.bankCountries"
														v-model="companyEntry.bank_info.country" label="Code Pays">
													</v-autocomplete>

												</v-col>

												<v-col cols="12">
													<v-text-field label="Numéro TVA" v-if="companyEntry.tva.status"
														hint="Numéro de TVA intracommunautaire de l'entreprise"
														v-model="companyEntry.tva.number"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="Banque" hint="Nom de la banque de l'entreprise"
														v-model="companyEntry.bank_info.bank"></v-text-field>
												</v-col>

												<v-col cols="12">
													<v-text-field label="IBAN / BIC" hint="IBAN/BIC de l'entreprise"
														v-model="companyEntry.bank_info.iban"></v-text-field>
												</v-col>

											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" align="center" justify="center">
					<v-card class="bt-success">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<v-spacer></v-spacer>
													<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
														class="neon evidence mb-4" width="200" @click="submit"
														@click.native="$emit('input', false)">
														Ajouter l'entreprise
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>

			</v-card>


		</v-dialog>
	</v-flex>

</template>


<script>
	const axios = require('axios');
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import Title from '@/components/graphics/title'
	import ImageUpload from '@/components/graphics/ImageUpload'

	export default {
		props: {
			'value': {
				type: Boolean,
				default: false
			},
			'id': {
				type: String,
				default: undefined
			},
			'compArray': {
				type: Array,
				default: Array
			},
			'modify': {
				type: Boolean,
				default: false
			}
		},
		components: {
			ImageUpload,
			ButtonTrigger,
			Title
		},
		data() {
			return {
				date: null,
				menu: false,

				companyEntry: this.$models.company,
				userInfo: this.$models.user,

				importIMG: null,
				uploadLoad: false,
			};
		},

		watch: {
			id: function () {
				if (this.modify) {
					this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(this.id)
						.onSnapshot(doc => {
							this.companyEntry = doc.data();

						if(this.companyEntry.birthdate) this.companyEntry.birthdate = this.$functions.getDateFormat(this.companyEntry.birthdate, "YYYY-MM-DD");
					});
				}
			},

			compArray: function () {
				this.companyEntry.nCompany = this.$functions.getNextIndex(this.compArray, "nCompany");
			}
		},

		computed: {
			formatDate() {
				return this.companyEntry.birthdate ? this.$moment(this.companyEntry.birthdate).format('DD/MM/YYYY') : ''
			}
		},

		methods: {
			resetFields(force = false) {
				if (this.modify || force) {
					this.companyEntry = {
						nCompany: this.this.$functions.getNextIndex(this.compArray, "nCompany")(),
						name: '',

						address: {
							street: '',
							city: '',
							region: '',
							country: '',
							postalCode: '',
						},

						avatar: '',

						phone: '',
						email: '',
						birthdate: '',
						website: '',
						bio: '',

						contact: {
							call: false,
							mail: false,
							sms: false,
							preference: '',
						},

						tva: {
							number: '',
							status: false
						},

						bank_info: {
							bank: '',
							country: ["FR", "KR"],
							iban: ''
						}
					}
				}
			},

			submit() {
				if (!this.$refs.form.validate()) return;

				var entreprise = {
					...this.companyEntry,

					'status': 3,
				}

				if (this.companyEntry.birthdate) entreprise.birthdate = new Date(this.companyEntry.birthdate);

				if (!this.modify) entreprise.creationDate = new Date();

				if (this.modify) {
					this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(this.id).set(
						entreprise, {
							merge: true
						}).then(() => {
						this.$refs.form.resetValidation();

						this.$store.dispatch('setSnackbar', {
							text: `L'entreprise a été modifiée !`
						});

						this.resetFields();
					});
				} else {
					this.$db.collection("companies").doc(this.userInfo.company).collection("companies").add(entreprise)
						.then(() => {
							this.$store.dispatch('setSnackbar', {
								text: `L'entreprise ${this.companyEntry.name} a été ajoutée !`
							});

							this.resetFields(true);
						});
				}

				this.$emit('input', false);
			},

			async addCompanyBySIRET() {
				var config = {
					method: 'get',
					url: 'https://api.insee.fr/entreprises/sirene/V3/siret/' + this.companyEntry.siret,
					headers: {
						'Accept': 'application/json',
						'Authorization': 'Bearer f39df87b-c8a7-3176-8b7b-eac78bb8c190'
					},
					data: {
						'quantity': '1',
						'offset': '0'
					}
				};
				axios(config).then((response) => {
					var comp = response.data.etablissement;

					this.companyEntry.birthdate = this.$moment(new Date(comp.uniteLegale
						.dateCreationUniteLegale)).format("YYYY-MM-DD");

					if (comp.uniteLegale.denominationUniteLegale) {
						this.companyEntry.name = comp.uniteLegale.denominationUniteLegale;
					} else {
						var sexe = comp.uniteLegale.sexeUniteLegale == "F" ? "Mme" : "M";
						this.companyEntry.name = sexe + " " + comp.uniteLegale.prenom1UniteLegale + " " + comp
							.uniteLegale.nomUniteLegale;
					}

					var config2 = {
						method: "get",
						url: 'https://api-adresse.data.gouv.fr/search/?q=' + comp.adresseEtablissement
							.numeroVoieEtablissement + "+" + comp.adresseEtablissement
							.typeVoieEtablissement + "+" + comp.adresseEtablissement
							.libelleVoieEtablissement + "&postcode=" + comp.adresseEtablissement
							.codePostalEtablissement,
						headers: {
							'Accept': 'application/json',
						},
						data: {
							'quantity': '1',
							'offset': '0'
						}
					}

					axios(config2).then((response) => {
						var address = response.data.features[0].properties;

						this.companyEntry.address.street = address.name;
						this.companyEntry.address.postalCode = address.postcode;
						this.companyEntry.address.city = address.city;
						this.companyEntry.address.country = "France";

						var splitContext = address.context.split(", ");
						this.companyEntry.address.region = splitContext[1];
					});
				}).catch(() => {
					this.$store.dispatch('setSnackbar', {
						text: `L'entreprise au numéro SIRET ${this.companyEntry.siret} n'a pas été trouvée dans le répertoire SIREN.`,
						color: 'error'
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
			});
		}
	};

</script>
