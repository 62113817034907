<template>
	<v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
		<DataTable title="Entreprises" type="companies" :company="userInfo.company" canDelete address showExpand :headers="$tableHeaders.companies" :items="companies" :itemKey="companyEntry.id" link="/record/company" />
	</v-col>
</template>

<style>
	#blurMe {
		filter: blur(10px);
		mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
	}

	#lateral {
		position: fixed;
		top: 15px;
		right: 15px;
		margin: 0 0 16px 16px;
	}

	#create .v-speed-dial {
		position: absolute;
	}

	#create .v-btn--floating {
		position: relative;
	}
</style>

<script>
	// import CompanyDialog2 from '@/components/modules/@company/CompanyDialog';
	// import Title from '@/components/graphics/title'
	import DataTable from '@/components/modules/#global/DataTable';


	export default {
		props: {
			'id': {
				type: String,
			},
			title: String,
			companies: Array,
			cols: Number,
			sm: Number,
			md: Number,
			lg: Number,
			xl: Number,
		},



		components: {
			// Title,
			DataTable,
			// CompanyDialog2
		},
		data() {
			return {
				loader: null,
				loading: false,
				dialogDeleteItem: false,
				date: null,
				menu: false,
				expanded: [],
				singleExpand: true,
				dialog: false,
				search: '',
				attrs: '',
				on: '',
				type: '',

				identifiant: '',
				delCompName: '',
				viewCustom: false,
				modifiedCustom: '',

				companyEntry: this.$models.company,
				userInfo: this.$models.user
			}
		},
		watch: {
			loader() {
				const l = this.loader
				this[l] = !this[l]

				setTimeout(() => (this[l] = false), 3000)

				this.loader = null
			},

		},

		methods: {
			openDeleteDialog(id, name) {
				this.dialogDeleteItem = true;

				this.identifiant = id;
				this.delCompName = name;
			},

			deleteCompany(idComp, nameComp) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(idComp), `L'entreprise ${nameComp} a été supprimée !`);
				this.dialogDeleteItem = false;
			},

			preModifyCompany(id) {
				this.modifiedCustom = id;

				this.viewCustom = true;

				this.dialog = true;
			},

			async viewCompany(id) {
				this.$router.replace({
					path: "record/company/" + id
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userInfo = doc.data();
			});
		}

	}
</script>